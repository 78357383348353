import React from 'react';
import './styles.scss'

const Home = () => {
    return (
        <div className='home-page'>
            <h1>
                COMING SOON
            </h1>
            <a href="https://nesthomemy.com" className='btn btn-lg btn-info'>Back To Main Page</a>
        </div>
    );
}

export default Home;