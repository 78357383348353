import React, { createContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes } from 'react-router';
import './App.scss';
import './keyframes.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Home } from './Pages';
import { AuthState } from './common/types';
import { getUser } from './common/UserUtils';

export const UserContext = createContext<AuthState | undefined>(undefined);

function App() {
	const [authState, setAuthState] = useState<AuthState | undefined>(undefined);

	useEffect(() => {
		setAuthState(getUser());
	}, []);

	return (
		<div className="App">
			<UserContext.Provider value={authState}>
				{/** Please update routes constant if there's a new page */}
				<Routes>
					<Route path="/" element={<Home />}></Route>
				</Routes>
			</UserContext.Provider>

			<ToastContainer
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme={'colored'}
            />
		</div>
	);
}

export default App;